<section id="about-me">
    <header>
        <h2>About Me</h2>
    </header>
    <div class="row">
        <div class="col-md-4 text-center">
            <img src="assets/juanjardimop.png" class="img-fluid avatar" height="250px" width="230px" alt="Juan Jardim">
        </div>
        <div class="col-md-8">
            <div class="personal-info">
                <div class="row">
                    <div class="info col-md-6 col-xs-12">
                        <span class="detail">Name: </span>
                        <span>Juan Jardim</span>
                    </div>
                </div>
                <div class="row">
                    <div class="info col-md-6 col-xs-12">
                        <span class="detail">LinkedIn: </span>
                        <span><a target="_blank" href="https://linkedin.com/in/juanjardim"
                                rel="noopener">linkedin.com/in/juanjardim</a></span>
                    </div>
                    <div class="info col-md-6 col-xs-12">
                        <span class="detail">GitHub: </span>
                        <span><a target="_blank" href="https://github.com/juanjardim"
                                rel="noopener">github.com/juanjardim</a></span>
                    </div>
                </div>
                <div class="row">
                    <div class="info col-md-6 col-xs-12">
                        <span class="detail">Email: </span>
                        <span><a
                                href="mailto:juanjardim@hotmail.com?Subject=Hello, Juan">juanjardim@hotmail.com</a></span>
                    </div>
                    <div class="info col-md-6 col-xs-12">
                        <span class="detail">Job: </span>
                        <span>Salesforce Technical Lead</span>
                    </div>
                </div>
            </div>
            <div class="personal-description">
                <p>
                    As a Salesforce Technical Lead based in the Lausanne area, I specialize in developing Lightning Web
                    Components (LWC) applications, Apex and integrating Salesforce with other systems. With extensive
                    experience working remotely with a team that is based in Spain, Portugal, Ireland, UK, I am
                    well-versed in
                    implementing best practices and streamlining business processes to ensure efficient data flow and
                    optimal user experience. As a Scrum Master, I am dedicated to facilitating the delivery of technical
                    solutions that meet our clients' needs and exceed their expectations.
                </p>
                <p>
                    I specialize in developing modular, scalable, and functional code that meets the unique needs of my
                    clients. With strong communication skills, I'm able to liaise effectively with clients and work
                    colleagues to ensure project success. I'm equally comfortable working as part of a team or
                    independently and am adept of prioritizing, and meeting tight deadlines under
                    pressure. Whether developing Lightning Web Components (LWC) applications, improving business
                    processes, or integrating Salesforce with other systems, I am committed to delivering technical
                    solutions that exceed expectations.
                </p>
                <p>
                    <a href="assets/cv_Juan_Jardim.pdf" target="_blank" rel="noopener" class="btn btn-lg resume"
                        role="button">download resume</a>
                </p>
            </div>
        </div>
    </div>
</section>