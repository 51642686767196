<section id="skills">
    <header>
        <h2>Skills</h2>
        <h3>My expertise</h3>
    </header>
    <div class="row">
        <div class="col-md-6 education">
            <div class="sub-heading">
                <h3><span><i class="fa fa-graduation-cap" aria-hidden="true"></i></span> Education</h3>
            </div>
            <div class="time-line">
                <ul>
                    <li>
                        <div class="time-period">2009 - 2012</div>
                        <div class="job-position">Master's Degree in Computer Science</div>
                        <div class="description">Here I learned how too build large applications and apply concepts like
                            UML, UX and
                            design patterns.</div>
                    </li>
                    <li>
                        <div class="time-period">2005 - 2009</div>
                        <div class="job-position">Bachelor's degree in Computer Science</div>
                        <div class="description">Here I learned advances concepts in Computer Science, especially in
                            Object Oriented
                            Programming (OOP).</div>
                    </li>
                    <li>
                        <div class="time-period">2003 - 2005</div>
                        <div class="job-position">School degree in programming</div>
                        <div class="description">With this degree I learned the basics of programing and how we can
                            build a website.
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 work-experience">
            <div class="sub-heading">
                <h3><span><i class="fa fa-briefcase" aria-hidden="true"></i></span> Work Experience</h3>
            </div>
            <div class="time-line">
                <ul>
                    <li>
                        <div class="time-period">April 2022 - Present</div>
                        <div class="job-position">Salesforce Technical Lead at Frontiers</div>
                        <div class="job-description">As a Salesforce Technical Lead at Frontiers, I am the primary point
                            of contact for all technical requirements and strive to implement industry best practices on
                            the platform. I place a strong emphasis on documenting both new and existing processes to
                            support the Salesforce team's development efforts. Additionally, I serve as a Scrum Master
                            to facilitate the delivery of technical solutions that align with our clients' needs.
                        </div>
                    </li>
                    <li>
                        <div class="time-period">October 2020 - February 2022</div>
                        <div class="job-position">Salesforce Developer at Frontiers</div>
                        <div class="job-description">Collaborated with a remote team based in Spain to develop Lightning
                            Web Components (LWC) applications, streamline business processes, and integrate Salesforce
                            with various other systems. Leveraged Azure Logic Apps to facilitate seamless integration
                            and ensure efficient data flow across platforms.
                        </div>
                    </li>
                    <li>
                        <div class="time-period">2020 - September 2020</div>
                        <div class="job-position">Software Engineer at IMD business school</div>
                        <div class="job-description">Here, I worked on Salesforce by creating components using Apex,
                            Lightning components
                            (Aura and Web),
                            Javascript and CSS for the MyIMD community.</div>
                    </li>
                    <li>
                        <div class="time-period">2016 - 2020</div>
                        <div class="job-position">Web Developer at IMD business school</div>
                        <div class="job-description">Here, I worked with different teams to develop the
                            internal and externals websites using EpiServer, MVC .NET, Javascript and CSS.</div>
                    </li>
                    <li>
                        <div class="time-period">2012 - 2016</div>
                        <div class="job-position">Web Developer at NOS Madeira (Portugal)</div>
                        <div class="job-description">Here, I worked with: emberJS and improve my knowledge in HTML5,
                            Javascript and
                            Bootstrap. I also worked with Zend Framework
                            and Node Js as backend technologies.</div>
                    </li>
                    <li>
                        <div class="time-period">2012 - 2015</div>
                        <div class="job-position">Invited Assistant Professor at University of Madeira</div>
                        <div class="job-description">Here, I taught the practical lecture of Computer Architecture
                            course, where we
                            explored VHDL, assembly and C.</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>