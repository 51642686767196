<section id="services">
    <header>
        <h2>Services</h2>
        <h3>what i can do for you</h3>
    </header>
    <div class="row areas">
        <div class="col-md-4">
            <div class="row align-items-center">
                <div class="col-2"><i class="fa fa-file-code-o" aria-hidden="true"></i></div>
                <div class="col">
                    <h3>Web Development</h3>
                    <p>I am able to develop large websites including the Front and Back-end using different
                        technologies.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row align-items-center">
                <div class="col-2"><i class="fa fa-lightbulb-o" aria-hidden="true"></i></div>
                <div class="col">
                    <h3>User Experience</h3>
                    <p>I am able to deliver websites that meet the exact need of the customer and have the best
                        Experience.</p>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row align-items-center">
                <div class="col-2"><i class="fa fa-mobile" aria-hidden="true"></i></div>
                <div class="col">
                    <h3>Responsive Design</h3>
                    <p>I am able to deliver websites fully responsive and with the best performance for mobile. </p>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <header>
        <h2>Certifications</h2>
        <h3>Salesforce</h3>
    </header>
    <div class="salesforce">
        <div style="margin-left:20px; margin-right:20px">
            <div class="row">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/SystemArchitect.png"
                        alt="Salesforce Certified System Architect">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/systemarchitect"
                                target="_blank">Salesforce Certified System Architect</a>
                        </h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Nov 2023</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/IDAccess.png"
                        alt="Salesforce Certified Identity and Access Management Architect">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/identityandaccessmanagementarchitect"
                                target="_blank">Salesforce Certified Identity and Access Management Architect</a>
                        </h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Nov 2023</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/Lifecycle.png"
                        alt="Salesforce Certified Development Lifecycle and Deployment Architect">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/developmentlifecycledeploymentarchitect"
                                target="_blank">Salesforce Certified Development Lifecycle and Deployment Architect</a>
                        </h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Feb 2023</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/Integration.png"
                        alt="Salesforce Certified Integration Architect">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/integrationarchitect"
                                target="_blank">Salesforce Certified Integration Architect</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Nov 2022</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/PlatformDeveloper2.png"
                        alt="Salesforce Certified Platform Developer II">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/platformdeveloperii"
                                target="_blank">Salesforce Certified Platform Developer II</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Jul 2022</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/ApplicationArchitect.png"
                        alt="Salesforce Certified Application Architect">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/applicationarchitect"
                                target="_blank">Salesforce Certified Application Architect</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Nov 2021</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/DataArchitect.png"
                        alt="Salesforce Certified Data Architect">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/dataarchitect"
                                target="_blank">Salesforce Certified Data Architect</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Nov 2021</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/Javascript1.png"
                        alt="Salesforce Certified JavaScript Developer I">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/javascriptdeveloperi"
                                target="_blank">Salesforce Certified JavaScript Developer I</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Aug 2021</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/SharingAndVisibilityDesigner.png"
                        alt="Salesforce Certified Sharing and Visibility Architect">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/sharingandvisibilityarchitect"
                                target="_blank">Salesforce Certified Sharing and Visibility Architect</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Mar 2021</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/SalesCloudConsultant.png"
                        alt="Salesforce Certified Sales Cloud Consultant">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/salescloudconsultant"
                                target="_blank">Salesforce Certified Sales Cloud Consultant</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Aug 2020</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/PlatformAppBuilder.png"
                        alt="Salesforce Certified Platform App Builder">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/platformappbuilder"
                                target="_blank">Salesforce Certified Platform App Builder</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Feb 2020</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/PlatformDeveloper1.png"
                        alt="Salesforce Certified Platform Developer I">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/platformdeveloperi"
                                target="_blank">Salesforce Certified Platform Developer I</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Sep 2019</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-auto">
                    <img class="salesforce-cert" src="assets/Administrator.png"
                        alt="Salesforce Certified Administrator">
                </div>
                <div class="col">
                    <div class="content-body">
                        <h4><a href="https://trailhead.salesforce.com/en/credentials/administrator"
                                target="_blank">Salesforce Certified
                                Administrator</a></h4>
                        <div class="status">
                            <div class="active-status"><span>Active</span></div>
                            <p><span>Issued Mar 2019</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <header>
        <h2>Technologies and Frameworks</h2>
    </header>
    <div class="technologies">
        <div class="row">
            <div class="col">
                <div class="html5 logo"></div>
            </div>
            <div class="col">
                <div class="bootstrap logo"></div>
            </div>
            <div class="col">
                <div class="angular logo"></div>
            </div>
            <div class="col">
                <div class="node logo"></div>
            </div>
            <div class="col">
                <div class="net logo"></div>
            </div>
        </div>
    </div>
</section>