<nav class="navbar navbar-expand-lg sticky-top">
    <div class="container">
        <a class="navbar-brand" href="#">Juan Jardim - Web Developer</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item active">
                    <a class="nav-link" href="#about-me" rel="noopener">About Me</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#skills" rel="noopener">Skills</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#services" rel="noopener">Services</a>
                </li>
            </ul>
        </div>
    </div>

</nav>